<template>
  <div>
    <span class="d-flex justify-content-between mt-4" :class="containerClass">
      <h2>Program Inquiries</h2>
      <b-btn variant="secondary" style="height: 40px" @click.prevent="openInfoModal"> Add Inquiry </b-btn>
    </span>
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input class="w-lg-auto" placeholder="Search..." v-model="searchTerm" @input="onSearch" />

                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-6 mx-0 px-0">
                  <v-select
                    id="program"
                    class="form-control v-select-custom"
                    label="title"
                    v-model="filteredProgram"
                    :reduce="(program) => program.id"
                    placeholder="Select Program"
                    :options="allPrograms"
                    :loading="areProgramsLoading"
                    @input="onFilterApplied"
                  >
                    <template #search="{ attributes, events }">
                      <input class="vs__search" v-bind="attributes" v-on="events" />
                    </template>

                    <template slot="option" slot-scope="option">
                      <div class="d-flex align-items-center">
                        <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                          <b-img :src="option.image" class="img-fluid" width="20" alt="Logo" v-if="option.image" />
                          <md-icon v-else>local_library</md-icon>
                        </fmv-avatar>
                        <span>{{ option.title }}</span>
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.title }}
                    </template>
                  </v-select>
                </div>
                <b-btn
                  class="ml-2"
                  :disabled="disableExportBtn"
                  @click.prevent="downloadCsvFile"
                  style="width: 150px"
                  exact
                  variant="primary"
                >
                  <i v-if="isFileDownloading" class="fas fa-circle-notch fa-spin"></i>
                  <span v-else>Export Inquiries</span></b-btn
                >
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="allInfoContacts"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
            @row-clicked="openDetailModal"
          >
            <template #cell(first_name)="data"> {{ data.item.first_name }} {{ data.item.last_name }}</template>

            <template #cell(email)="data"> {{ data.item.email }}</template>
            <template #cell(phone)="data"> {{ data.item.phone }}</template>
            <template #cell(program)="data"> {{ data.item.program.title }}</template>
            <template #cell(types)="data"> {{ $t(`programInquiryTypes.${data.item.types}`) }}</template>
            <template #cell(contacted_at)="data">
                  <b-badge 
                    v-b-tooltip.hover.v-primary.no-delay

                    :title="'Click to mark as ' + (data.item.contacted_at ? 'not contacted' : 'contacted')"
                    :variant="data.item.contacted_at ? 'success' : 'primary'"
                    role="button"
                    class="clickableitem clickable-badge"
                    @click.stop="handleContactStatusUpdate(data.item)"
                    :disabled="isStatusUpdating === data.item.id"
                  >
                    {{ data.item.contacted_at ? formatDateTime(data.item.contacted_at) : 'No' }}
                    <i class="fas fa-pencil-alt ml-1"></i>

                  </b-badge>
              </template>
            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <a :href="`mailto:${data.item.email}`"
                ><i class="material-icons mr-1" v-b-popover.hover.left title="Email requestee">email</i></a
              >
              <a class="ml-2" :href="`tel:${data.item.phone}`" v-b-popover.hover.left title="Call requestee"
                ><i class="material-icons mr-1">phone</i></a
              >
              <a
                v-if="!showSMS"
                class="ml-2"
                :href="`sms:${data.item.phone}`"
                v-b-popover.hover.left
                title="SMS requestee"
                ><i class="material-icons mr-1">sms</i></a
              >
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalInfoContacts"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="info-table"
            />
          </div>
        </div>
      </div>
    </div>
    <inquiry-detail-modal
      :subdomain="get(getLoggedInUser, 'linked_entity.transition_sub')"
      :showModal="showDetailModal"
      :contact-info="contact"
      @close="hideDetailModal"
    />
    <info-modal :showModal="showInfoModal" :program="{ id: filteredProgram }" @close="hideInfoModal" />
  </div>
</template>

<script>
import { get, debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants';
import { formatDateTime } from '../../../common/utils';
import InquiryDetailModal from './InquiryDetailModal.vue';
import InfoModal from '../../SchoolPublicLanding/Programs/InfoModal/InfoModal.vue';
import vSelect from 'vue-select';
import { FmvAvatar } from 'fmv-avatar';
export default {
  components: {
    MdIcon,

    Pagination,
    InquiryDetailModal,
    InfoModal,
    vSelect,
    FmvAvatar,
  },
  extends: Page,

  data() {
    return {
      title: 'Program Inquiries',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalInfoContacts: 0,
      searchTerm: '',
      areInfoContactsLoading: false,
      allInfoContacts: [],
      showDetailModal: false,
      contact: {},
      infoData: {},
      showInfoModal: false,
      filteredProgram: null,
      allPrograms: [],
      areProgramsLoading: false,
      isFileDownloading: false,
      showSMS: false,
      isStatusUpdating: null,

    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Program Info & Contacts', active: true },
      ];
    },
    disableExportBtn() {
      return !this.totalInfoContacts || this.isFileDownloading;
    },
    tableColumns() {
      return [
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
        {
        key: 'contacted_at',
        label: 'Contacted',
        tdClass: 'clickable-item',
      },
        { key: 'first_name', label: 'Name', tdClass: 'clickable-item' },
        { key: 'email', label: 'Email', tdClass: 'clickable-item' },
        { key: 'phone', label: 'Phone', tdClass: 'clickable-item' },
        { key: 'program', label: 'Program', tdClass: 'clickable-item' },
        { key: 'types', label: 'Type', tdClass: 'clickable-item' },
        {
          key: 'created_at',
          label: 'Inquired On',
          tdClass: 'clickable-item',
          formatter: (value) => (value ? formatDateTime(value) : value),
        },
        
      ];
    },
  },

  methods: {
    ...mapActions('programInfo', ['getAllProgramInfos', 'getProgramInfo', 'getInquiriesCsvUrl','updateContactStatus']),
    ...mapActions('program', ['getAllPrograms', 'getAllScholarshipList']),
    ...mapActions('fileDownload', ['downloadFile']),
    get,
    formatDateTime,
    openInfoModal() {
      this.showInfoModal = true;
    },

    hideInfoModal() {
      this.showInfoModal = false;
      this.fetchInfoContacts();
    },
    async handleContactStatusUpdate(item) {
          try {
            this.isStatusUpdating = item.id;
            
            await this.updateContactStatus({
              id: item.id,  // Add the id parameter
              data: { contacted: !item.contacted_at }  // Wrap contacted in a data object
            });
            
            // Refresh the list to get updated status
            await this.fetchInfoContacts(this.currentPage);
            
            this.makeToast({
              variant: 'success',
              msg: `Contact status updated successfully`
            });
          } catch (error) {
            console.error('Update failed:', error); // Add error logging
            this.makeToast({
              variant: 'danger',
              msg: this.$t('generalMsgs.genErrorMsg')
            });
          } finally {
            this.isStatusUpdating = null;
          }
        },
    async downloadCsvFile() {
      this.isFileDownloading = true;

      try {
        const resp = await this.getInquiriesCsvUrl();
        if (resp.data.url) {
          await this.downloadFile({ fileUrl: resp.data.url, removeTimestamp: true });

          const h = this.$createElement;
          this.makeToast({
            variant: 'success',
            msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
            toaster: 'b-toaster-bottom-left',
          });
        } else {
          this.makeToast({ variant: 'danger', msg: 'Cannot export the file!' });
        }
      } catch (_err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isFileDownloading = false;
    },

    openDetailModal(data) {
      this.$router.push({ name: 'info-detail', params: { id: data.id } });
    },

    hideDetailModal() {
      this.showDetailModal = false;

      this.$router.push({ name: 'manage-info-list' });
    },

    async fetchInfo(infoId) {
      this.isLoading = true;
      const data = await this.getProgramInfo(infoId);
      this.infoData = data;
      this.isLoading = false;
    },

    async fetchInfoContacts(pageNum = 1, params = {}) {
      this.areInfoContactsLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllProgramInfos({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
        ...(this.filteredProgram && { program: this.filteredProgram }),
      });
      this.allInfoContacts = response.data.results;
      this.currentPage = pageNum;
      this.totalInfoContacts = response.data.count;
      this.areInfoContactsLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchInfoContacts(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchInfoContacts();
    },

    onSearch() {
      this.debouncedSearchInfoContacts(this);
    },

    debouncedSearchInfoContacts: debounce((vm) => {
      vm.fetchInfoContacts();
    }, 500),

    onFilterApplied() {
      this.$router.push({
        name: 'manage-info-list',
        query: { ...(this.filteredProgram && { program: this.filteredProgram }) },
      });

      this.fetchInfoContacts();
    },

    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getAllPrograms({});
      this.allPrograms = response.data;

      this.areProgramsLoading = false;
    },
  },

  async mounted() {
    this.showSMS = navigator.userAgent.includes('Win');
    if (this.$route.query.program) {
      this.filteredProgram = parseInt(this.$route.query.program);
    }
    this.fetchInfoContacts();
  },
};
</script>
<style>
.clickable-badge {
  transition: all 0.2s ease;
  padding: 10px; /* Reduced right padding */
  font-size: 12px;
  height: 5px; /* Slightly reduced height */
}

.clickable-badge:hover {
  opacity: 0.85;
  transform: translateY(-1px);
}

.clickable-badge:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>