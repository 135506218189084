<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      info-text="Manage Product inventory of your school so that students can buy while enrolling."
    />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Products"
                    v-model="searchTerm"
                    @input="onSearch"
                  />

                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-6 mx-0 px-0"></div>
                <b-btn class="ml-2" :to="{ name: 'add-product' }" exact variant="primary" v-text="'Add Product'" />
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="allProducts"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(product_title)="data">
              <div class="d-flex align-items-center">
                <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" class="mr-2">
                  <b-img
                    :src="data.item.product_url"
                    class="img-fluid"
                    width="40"
                    alt="Logo"
                    v-if="data.item.product_url"
                  />
                  <i class="fas fa-university fa-lg" v-else></i>
                </fmv-avatar>

                {{ data.item.product_title }}
              </div></template
            >
            <template #cell(product_description)="data"> {{ data.item.product_description }}</template>
            <template #cell(price)="data"> {{ $n(data.item.price, 'currency', 'en-US') }}</template>
            <template #cell(category)="data">   {{ categoryMap[data.item.category] || data.item.category }}</template>

            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <router-link
                :to="{
                  name: 'edit-product',
                  params: { id: data.item.id },
                }"
                class="svg-icon mr-2"
                v-b-popover.hover.right
                :title="'Edit'"
              >
                <i class="material-icons">edit</i>
              </router-link>
              <a
                href="#"
                @click.prevent="removeProduct(data.item.id)"
                v-b-popover.hover.right
                :title="'Delete Product'"
              >
                <i class="material-icons text-danger">delete</i>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalProducts"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="schools-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import MdIcon from '@/components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '@/common/constants';
import { FmvAvatar } from 'fmv-avatar';
export default {
  components: { MdIcon, PageHeader, Pagination, FmvAvatar },
  extends: Page,

  data() {
    return {
      title: 'Manage Product Inventory',
      areProgramsLoading: false,
      program: null,
      allPrograms: [],
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalProducts: 0,
      searchTerm: '',
      allProducts: [],
    };
  },

  computed: {
    categoryMap() {
      return {
      'apparel': 'Apparel',
      'accessories': 'Accessories',
      'classroom_supplies': 'Classroom Supplies',
      'clinical_supplies': 'Clinical/Lab Supplies',
      };
    },
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Manage Product Inventory', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'product_title', label: 'Product' },
        // { key: 'product_description', label: 'Description' },
        { key: 'price', label: 'Price' },
        { key: 'category', label: 'Category' },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('products', ['getAllProducts', 'deleteProduct']),
    onFilterApplied() {
      this.fetchProducts();
    },
    async fetchProducts(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllProducts({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.allProducts = response.data.results;
      this.currentPage = pageNum;
      this.totalProducts = response.data.count;
      this.isLoading = false;
    },
    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getAllPrograms({});
      this.allPrograms = response.data;

      this.areProgramsLoading = false;
    },
    onPageChange(pageNum) {
      this.fetchProducts(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchProducts();
    },

    onSearch() {
      this.debouncedSearchProducts(this);
    },

    debouncedSearchProducts: debounce((vm) => {
      vm.fetchProducts();
    }, 500),

    async removeProduct(id) {
      this.isLoading = true;
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to delete the selected product.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deleteProduct(id);
          this.fetchProducts();
          this.makeToast({ variant: 'success', msg: 'Product deleted successfully!' });
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
  },

  async mounted() {
    if (this.$route.query.program) {
      this.program = Number(this.$route.query.program);
    }
    this.fetchProducts();
  },
};
</script>
