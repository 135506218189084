<template>
  <div :class="containerClass">
    <page-header title="Inquiry Detail" :container-class="null" class="mb-16pt" />
    <div class="row">
      <div class="col-lg-10">
        <div class="d-flex align-items-start">
          <span><b>Name:</b> {{ get(infoData, 'first_name') }} {{ get(infoData, 'last_name') }} </span>
        </div>

        <div class="d-flex align-items-between">
          <span><b>Email:</b> {{ get(infoData, 'email') }} </span>
        </div>

        <div class="d-flex align-items-between">
          <span><b>Phone:</b> {{ get(infoData, 'phone') }} </span>
        </div>

        <div class="d-flex align-items-between">
          <span><b>Permission To Text:</b> {{ get(infoData, 'is_text_allowed') ? 'Yes' : 'No' }} </span>
        </div>

        <div class="d-flex align-items-between">
          <span><b>Program:</b> {{ get(infoData, 'program.title', '') }} </span>
        </div>

        <div class="d-flex align-items-between">
          <span><b>Type:</b> {{ $t(`programInquiryTypes.${get(infoData, 'types', '')}`) }} </span>
        </div>

        <div v-if="get(infoData, 'types') === 'scholarship' && get(infoData, 'extra.scholarships.length')">
          <div><b> Sponsorships: </b></div>
          <ul>
            <li class="mb-1" v-for="scholarship in get(infoData, 'extra.scholarships')" :key="scholarship.id">
              <a
                class="text-primary"
                :href="`${subdomain || 'app.jointransition.com'}/sponsorships/${scholarship.id}`"
                target="_blank"
              >
                {{ scholarship.title }}
              </a>
            </li>
          </ul>
        </div>

        <div class="d-flex align-items-between" v-if="get(infoData, 'created_at')">
          <span><b>Inquired At:</b> {{ formatDateTime(infoData.created_at) }} </span>
        </div>
        <div class="d-flex align-items-between">
            <b>Contacted:</b>&nbsp;
            <b-badge 
              v-b-tooltip.hover.v-primary.no-delay
              :title="'Click to mark as ' + (get(infoData, 'contacted_at') ? 'not contacted' : 'contacted')"
              :variant="get(infoData, 'contacted_at') ? 'success' : 'primary'"
              role="button"
              class="clickableitem clickable-badge"
              @click="handleContactStatusUpdate(!get(infoData, 'contacted_at'))"
              :disabled="isLoading"
            >
              {{ get(infoData, 'contacted_at') ? formatDateTime(infoData.contacted_at) : 'No' }}
              <i class="fas fa-pencil-alt ml-1"></i>

            </b-badge>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="d-flex justify-content-end">
          <b-dropdown text="Contact" variant="primary" class="ml-2">
            <b-dropdown-item :href="`mailto:${get(infoData, 'email')}`"
              ><i class="material-icons mr-1">email</i>Email</b-dropdown-item
            >
            <b-dropdown-item :href="`tel:${get(infoData, 'phone')}`"
              ><i class="material-icons mr-1">phone</i>Call</b-dropdown-item
            >
            <b-dropdown-item v-if="!showSMS" :href="`sms:${get(infoData, 'phone')}`"
              ><i class="material-icons mr-1">sms</i>SMS</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </div>
    <div class="mt-2"><b>Original Inquiry:</b></div>
    <div v-safe-html="message" class="bg-light w-100 px-2 py-1 rounded mb-2" />
    <page-separator v-if="get(infoData, 'extra.chat_details.length')">
      <b-link v-b-toggle="'schedule-section'" class="accordion__toggle p-0">
        <span class="flex">Transcript</span>
        <md-icon class="accordion__toggle-icon">
          {{ chatVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
        </md-icon>
      </b-link>
    </page-separator>

    <b-collapse
      v-if="get(infoData, 'extra.chat_details.length')"
      id="schedule-section"
      accordion="accordionId"
      :visible="chatVisible"
      class="accordion__menu"
      @hide="chatVisible = false"
      @show="chatVisible = true"
    >
      <div class="container chat_container">
        <div
          v-for="(chat, index) in get(infoData, 'extra.chat_details')"
          :key="index"
          :class="chat.type === 'bot' ? 'chat-wrapper ai' : 'chat-wrapper'"
        >
          <div class="d-flex">
            <div class="profile">
              <img
                :style="`background-color: ${
                  chat.type === 'bot'
                    ? get(getLoggedInUser.linked_entity, 'primary_color')
                    : get(getLoggedInUser.linked_entity, 'secondary_color')
                }`"
                :src="chat.type === 'bot' ? BotImg : UserImg"
                alt="bot"
              />
            </div>

            <div class="col-10 ml-2">
              <span v-html="formatText(chat.message)"></span>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import { get, trim } from 'lodash';
import { formatDateTime, formatHrefLink } from '@/common/utils';
import BotImg from '@/assets/images/support_bots/8.svg';
import UserImg from '@/assets/images/support_bots/user.svg';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import PageHeader from '@/components/Ui/PageHeader.vue';

export default {
  extends: Page,
  components: { PageSeparator, PageHeader },
  data() {
    return {
      title: 'Inquiry Detail',
      BotImg,
      UserImg,
      infoData: null,
      isLoading: false,
      windowWidth: window.innerWidth,
      message: '',
      chatVisible: true,
      showSMS: false,
      conversation: [
        {
          id: 0,
          message:
            "Welcome to School name's chat support. I am here to answer any questions you may have about our Certified Nursing Program. How can I assist you today?",
          isBot: true,
        },
        {
          id: 1,
          message:
            "Welcome to School name's chat support. I am here to answer any questions you may have about our Certified Nursing Program. How can I assist you today?",
          isBot: false,
        },
        {
          id: 2,
          message:
            "Welcome to School name's chat support. I am here to answer any questions you may have about our Certified Nursing Program. How can I assist you today?",
          isBot: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isMobileScreen() {
      return this.windowWidth <= 420;
    },
    isFormLoading() {
      return this.isLoading;
    },
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Programs', to: { name: 'manage-programs' } },
        {
          text: 'Manage FAQs',
          to: { name: 'prg-faq-management-list', params: { programId: this.$route.params.programId } },
        },
        { text: this.$route.params.id ? 'Edit FAQ' : 'Add FAQ', active: true },
      ];
    },
  },
  methods: {
    ...mapActions('program', ['createProgramFaq', 'updateProgramFaq', 'getProgramFaq']),
    ...mapActions('programInfo', ['getAllProgramInfos', 'getProgramInfo', 'getInquiriesCsvUrl','updateContactStatus']),
    get,
    formatDateTime,
    formatHrefLink,
    formatText(string) {
      const result = trim(string, '\n');
      return result.replace(/\n/g, '<br />');
    },
    async handleContactStatusUpdate(contacted) {
    try {
        this.isLoading = true;
        
        await this.updateContactStatus({
            id: this.$route.params.id,
            data: { contacted }
        });
        
        // Refresh the inquiry details
        await this.fetchInfo();
        
        this.makeToast({
            variant: 'success',
            msg: `Contact status ${contacted ? 'marked as contacted' : 'marked as pending'}`
        });
    } catch (error) {
        this.makeToast({
            variant: 'danger',
            msg: this.$t('generalMsgs.genErrorMsg')
        });
    } finally {
        this.isLoading = false;
    }
},
    async fetchInfo() {
      this.isLoading = true;
      const data = await this.getProgramInfo(this.$route.params.id);
      this.infoData = data;

      this.message = '';
      let htmlMessage = this.infoData.comments;
      const htmlEntities = [
        { regex: '&', entity: '&amp;' },
        { regex: '>', entity: '&gt;' },
        { regex: '<', entity: '&lt;' },
      ];
      htmlEntities.forEach((ent) => {
        const reg = new RegExp(ent.regex, 'g');
        htmlMessage = htmlMessage.replace(reg, ent.entity);
      });
      this.message = htmlMessage.replace(/(?:\r\n|\r|\n)/g, '<br>');
      this.isLoading = false;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  async mounted() {
    this.showSMS = navigator.userAgent.includes('Win');
    window.addEventListener('resize', this.handleResize);

    try {
      if (this.$route.params.id) {
        this.fetchInfo();
      }
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style scoped>
.clickable-badge {
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 10px; /* Reduced right padding */
  font-size: 12px;
  height: 5px; /* Slightly reduced height */
}

.clickable-badge:hover {
  opacity: 0.85;
  transform: translateY(-1px);
}

.clickable-badge:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

</style>