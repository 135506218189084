<template>
  <div>
    <page-header :title="title" :container-class="containerClass" :breadcrumb="breadcrumb" />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input class="w-lg-auto" placeholder="Search..." v-model="searchTerm" @input="onSearch" />

                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="allWaitListEntries"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap sticky-lcol-table"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(first_name)="data"> {{ data.item.first_name }} {{ data.item.last_name }}</template>

            <template #cell(email)="data"> {{ data.item.email }}</template>
            <template #cell(phone)="data"> {{ data.item.phone }}</template>
            <template #cell(date)="data"> {{ formatFullDate(data.item.created_at) }} </template>

            <template #cell(status)="data">
              <b-badge
                :class="
                  'border border-' + (data.item.status === 'informed' ? `success badge-success` : `danger badge-danger`)
                "
                variant="none"
              >
                {{ $t(`waitlistStatuses.${data.item.status}`) }}
              </b-badge>
            </template>
            <template #cell(informed)="data"> 
              {{ data.item.informed ? formatFullDateTime(data.item.informed) : '' }}
            </template>
            <template #cell(program)="data"> {{ data.item.program.title }}</template>
            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <a :href="`mailto:${data.item.email}`"
                ><i class="material-icons mr-1" v-b-popover.hover.left title="Email">email</i></a
              >
              <a class="ml-2" :href="`tel:${data.item.phone}`" v-b-popover.hover.left title="Call"
                ><i class="material-icons mr-1">phone</i></a
              >
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalEntries"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="info-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce } from 'lodash';
import { mapActions } from 'vuex';
import { formatFullDate,formatFullDateTime } from '@/common/utils';
import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants';

export default {
  components: { MdIcon, PageHeader, Pagination },
  extends: Page,

  data() {
    return {
      title: 'Program Waitlist',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalEntries: 0,
      searchTerm: '',

      allWaitListEntries: [],
      isFileDownloading: false,
      formatFullDate,
      formatFullDateTime,
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Programs', to: { name: 'manage-programs' } },
        ...(this.$route.params.name ? [{ text: this.$route.params.name, active: true }] : []),
        { text: 'WaitList', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'first_name', label: 'Name' },
        { key: 'email', label: 'Email' },
        { key: 'phone', label: 'Phone' },
        { key: 'program', label: 'Program' },
        { key: 'date', label: 'Waitlisted' },
        { key: 'status', label: 'Status' },
        { key: 'informed', label: 'Informed On' },

        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('program', ['getWaitlist']),

    async fetchWaitList(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getWaitlist({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        program_id: this.$route.query.program,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),

        ...params,
      });
      this.allWaitListEntries = response.data.results;
      this.currentPage = pageNum;
      this.totalEntries = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchWaitList(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchWaitList();
    },

    onSearch() {
      this.debouncedSearchWaitList(this);
    },

    debouncedSearchWaitList: debounce(vm => {
      vm.fetchWaitList();
    }, 500),
  },

  async mounted() {
    this.fetchWaitList();
  },
};
</script>
